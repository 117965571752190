$info-color: #16aaff;
$primary-color: #3f6ad8;
$success-color: #3ac47d;
$warning-color: #f7b924;
$danger-color: #d92550;
$orange-color: #ff5733;

$white-primary: #fff;
$white-secondary: #fafbfc;
$white-darker: #c2c2c2;

$black-primary: #252525; // First: #252525,
$black-secondary: #2e2e2e;
$black-darker: #161616;
$black-light: #8b8b8b;

$modal-black-bg: #1f1f1f;

$text-white-primary: #e6e6e6;
$text-white-secondary: #fafbfc;
$text-white-darker: #c2c2c2;
$text-black-primary: #495057;
